<template>
  <div id="no-content-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-journal-x"></i>
          <span style="margin-left: 5px"> No Content </span>
        </b-card-title>
        <!-- filter component -->
        <div class="d-flex">
          <b-button
            variant="outline-success"
            class="mr-1"
            @click="exportData()"
          >
            <feather-icon icon="FileTextIcon" />
            <span style="margin-left: 2px">Export</span>
          </b-button>
          <!-- date filter -->
          <date-picker-component />
          <!-- keyword filter -->
          <b-input-group class="input-group-merge ml-1" style="width: 20vw">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="name"
              style="height: 3rem"
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table-component -->
      <table-component
        v-else
        :data="table_data"
        :is_row_clicked="true"
        :viewDetail="viewDetail"
        class="mt-1"
      />
    </b-card>
    <!-- detail modal component -->
    <detail-modal-component ref="detail" />
  </div>
</template>
<script>
import $ from "jquery";
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import datePicker from "@/views/components/datePicker.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import detailNoContentModalVue from "@/views/components/no-content/detailNoContentModal.vue";
import axios from "axios";

export default {
  name: "NoContentLogs",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "table-component": dataTable,
    "loader-component": loader,
    "date-picker-component": datePicker,
    "detail-modal-component": detailNoContentModalVue,
  },
  data() {
    return {
      is_loading: true,
      from_date: this.$route.query.from_date,
      to_date: this.$route.query.to_date,
      tier: null,
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      name: null,
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "table-header text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "_id",
            label: "nama scraper",
            thClass: "table-header text-left",
            tdClass: "text-left",
          },
          {
            key: "count",
            label: "jumlah berita",
            thClass: "table-header text-center",
            tdClass: "text-center",
          },
        ],
        items: [],
      },
      cancelToken: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.items = this.$route.query.items ? this.$route.query.items : 10;
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      this.getData();
    },
    name() {
      if (this.name.length == 0 || this.name.length > 2) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
  },
  methods: {
    exportData() {
      const params = {
        from_date: this.from_date,
        to_date: this.to_date,
        ...(this.name ? { name: this.name } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      const url = `${process.env.VUE_APP_API_URL}stream/no-content/export?${query}`;
      window.open(url);
    },
    getData() {
      this.is_loading = true;
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        page: this.page,
        items: this.items,
        ...(this.name ? { name: this.name } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "stream/no-content?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelToken.token })
        .then((res) => {
          if (res.status == 200 && res.data.no_content.length > 0) {
            this.table_data.items = res.data.no_content;
            this.table_data.page_info = res.data.page_info[0];
          } else {
            this.table_data.items = [];
            this.table_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.table_data.items.length > 0) {
            this.is_loading = false;
          }
        });
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    viewDetail(id) {
      this.$bvModal.show("modal-detail-no-content");
      this.$refs.detail.getData(id);
    },
  },
};
</script>
